export const checkLocalStorageIsOld = () => {
  const limit = 24 * 3600 * 1000;
  const sellerClaimsData = localStorage.getItem('sellerClaimsData');

  if(!sellerClaimsData) {
    return null
  } else if (sellerClaimsData && +new Date() - JSON.parse(sellerClaimsData as string).time > limit) {
    localStorage.clear();
    return null
  } else if (sellerClaimsData && +new Date() - JSON.parse(sellerClaimsData as string).time < limit) {
    return JSON.parse(sellerClaimsData as string).token
  }
}