import Layout from 'components/layout/Layout';
import PrivateRoute from 'configs/private-route/PrivateRoute';
import { paths } from 'configs/routes';
import ErrorBoundary from 'helpers/ErrorBoundary';
import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import NotFoundPage from 'pages/404/NotFoundPage';
import {useAppSelector} from "./hooks/redux";
import NewPage from 'pages/companies/new/New';
const Login = lazy(() => import('pages/login/Login'));
const Manually = lazy(() => import('pages/companies/create/manually/Manually'));
const Upload = lazy(() => import('pages/companies/create/upload/Upload'));
const Companies = lazy(() => import('pages/companies/Companies'));
const EditCompany = lazy(() => import('pages/companies/edit/EditCompany'));
const Shipments = lazy(() => import('pages/shipments/Shipments'));
const SelectedShipment = lazy(() => import('pages/shipments/selected-shipment/SelectedShipment'));

const App = () => {

  const token = useAppSelector((state) => state.generalSlice.token);

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Layout>
          <Suspense fallback={null}>
            <Routes>
              <Route path='*' element={<NotFoundPage />} />
              <Route path='/' element={
                !token
                  ? <Navigate replace to={paths.login} />
                  : <Navigate replace to={paths.companies} />
              }
              />
              <Route path={paths.login} element={<Login />} />
              <Route element={<PrivateRoute />}>
                <Route path={paths.companies} element={<Companies />} />
                <Route path={paths.manually} element={<Manually />} />
                <Route path={paths.upload} element={<Upload />} />
                <Route path={paths.editById} element={<NewPage />} />
                <Route path={paths.shipments} element={<Shipments />} />
                <Route path={paths.shipmentById} element={<SelectedShipment />} />
              </Route>
            </Routes>
          </Suspense>
        </Layout>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
