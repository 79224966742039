import { paths } from 'configs/routes';
import { useAppSelector } from 'hooks/redux';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  const isAuthorized = useAppSelector((state) => state.loginSlice.isAuthorized);
  // const token = useAppSelector((state) => state.generalSlice.token);

  return <>{isAuthorized ? <Outlet /> : <Navigate to={paths.login} />}</>;
};

export default PrivateRoute;
