import React, {PropsWithChildren} from 'react';
import { Wrapper, MainWrapper } from './styled';
import Header from 'components/header/Header';
import {useAppSelector} from 'hooks/redux';

const Layout = ({ children }: PropsWithChildren) => {
  const isAuthorized = useAppSelector((state) => state.loginSlice.isAuthorized);

  return (
    <Wrapper $isAuthorized={isAuthorized}>
      <Header />
      <MainWrapper $isAuthorized={isAuthorized}>{children}</MainWrapper>
    </Wrapper>
  );
};

export default Layout;
