import { createAsyncThunk } from '@reduxjs/toolkit';
import { customAxios } from 'api/api';
import { GeneralState } from 'store/general/common';
import { ShipmentInvoiceTypes, ShipmentPackingSlipTypes, ShipmentSKUDescriptionTypes } from 'types/shipment/types';
import { PaginationParameters } from 'types/general/types';
import { AxiosError } from "axios";
import { setDescriptionError } from "./shipmentSlice";

export const postShipmentInvoice = createAsyncThunk(
  'shipment/postShipment',
  async ({ id, invoiceId, invoiceDate, itemsSku }: ShipmentInvoiceTypes, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState() as GeneralState;
      const config = {
        headers: {
          Authorization: `Bearer ${state.generalSlice.token}`,
        },
      };
      return await customAxios
        .post(`shipment/${id}/invoice`, { invoiceId, invoiceDate, itemsSku }, config)
    } catch (error: AxiosError | any) {
      return rejectWithValue(error.response.status);
    }
  }
);

export const patchShipmentSKUDescription = createAsyncThunk(
  'shipment/postShipment',
  async ({ id, items }: ShipmentSKUDescriptionTypes, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState() as GeneralState;
      const config = {
        headers: {
          Authorization: `Bearer ${state.generalSlice.token}`,
        },
      };
      const res = await customAxios
        .patch(`/shipment/${id}/item-description`, { items }, config)

      return res.data;
    } catch (error) {
      dispatch(setDescriptionError('Description field cannot be empty.'))
      return rejectWithValue(error);
    }
  }
);

export const postShipmentPackingSlip = createAsyncThunk(
  'shipment/postShipment',
  async ({ id, itemsSku }: ShipmentPackingSlipTypes, { getState, rejectWithValue }) => {
    try {
      const state = getState() as GeneralState;
      const config = {
        headers: {
          Authorization: `Bearer ${state.generalSlice.token}`,
        },
      };
      return await customAxios.post(`shipment/${id}/packing-slip`, { itemsSku }, config);

      // return res;
    } catch (error) {
      return rejectWithValue(`Failed to send shipment data, ${error}`);
    }
  }
);

export const getShipments = createAsyncThunk(
  'company/getShipments',
  async ({ page, limit, search }: PaginationParameters, { getState, rejectWithValue }) => {
    try {
      const state = getState() as GeneralState;
      const config = {
        headers: {
          Authorization: `Bearer ${state.generalSlice.token}`,
        },
        params: {
          page: page,
          limit: limit,
          search: search
        },
      };
      const res = await customAxios.get(`shipment`, config);
      return res.data;
    } catch (error) {
      return rejectWithValue(`Failed to fetch shipments, ${error}`);
    }
  }
);

export const getShipmentById = createAsyncThunk(
  'shipment/getShipmentById',
  async (id: string, { getState, rejectWithValue }) => {

    console.log("Shipment id ====", id)
    try {
      const state = getState() as GeneralState;
      const config = {
        headers: {
          Authorization: `Bearer ${state.generalSlice.token}`,
        },
      };
      const res = await customAxios.get(`shipment/${id}`, config);
      return res.data;
    } catch (error) {
      return rejectWithValue(`Failed to fetch shipment by id, ${error}`);
    }
  }
);
