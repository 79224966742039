import { createAsyncThunk } from '@reduxjs/toolkit';
import { customAxios } from 'api/api';
import { setToken } from 'store/general/generalSlice';
import { LoginTypes } from 'types/login/types';

export const postLogin = createAsyncThunk(
  'login',
  async ({ username, password }: LoginTypes, { rejectWithValue, dispatch }) => {
    try {
      const res = await customAxios.post('auth/login', { username, password }).then((res) => {
        if (res.data.token) {
          localStorage.setItem('sellerClaimsData', JSON.stringify({token: res.data.token, time: +new Date()}))
          dispatch(setToken(res.data.token));
        }
        return res.data;
      });

      return res.data;
    } catch (error) {
      return rejectWithValue(`Failed to send login data, ${error}`);
    }
  }
);
