import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

import styles from './UploadImage.module.scss';
import { getCroppedImg } from './util';

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const UploadImage = ({ setBase64Image, setImageBlob, close }) => {
  const [imageSrc, setImageSrc] = useState(undefined);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [zoom, setZoom] = useState(1);
  //   const [loading, setLoading] = useState(false);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      console.log('file size', file.size);

      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
      //   console.log('imageDataUrl', imageDataUrl);
      //   props.setImageFileName(file.name);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    // setLoading(true);
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      console.log(croppedImage);
      // setCroppedImage(croppedImage);
      setImageBlob(croppedImage.blob);

      setBase64Image(croppedImage.base64);
      //   setLoading(false);
      close(false);
    } catch (e) {
      //   setLoading(false);
      console.error(e);
    }
    // eslint-disable-next-line
  }, [imageSrc, croppedAreaPixels]);

  return (
    <div>
      {imageSrc ? (
        <div className={styles.uploadPopup}>
          <div className={styles.content}>
            <div style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }} onClick={close}>
              X
            </div>
            <div className={styles.cropperWrpr}>
              <Cropper
                image={imageSrc}
                crop={crop}
                aspect={5 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                zoom={zoom}
              />
            </div>
            <div className={`df acsa ${styles.sliderWrapper}`}>
              <div className={styles.innerSliderWrapper}>
                <Slider draggableTrack={true} min={1} max={4} step={0.01} onChange={(range) => setZoom(range)} />
              </div>
            </div>
            <button
              className={`df acc ffc ffsr fs14 ${styles.selectBtn}`}
              onClick={(e) => {
                e.preventDefault();
                showCroppedImage();
              }}
            >
              Set Image
            </button>
          </div>
        </div>
      ) : (
        <div className={styles.uploadPopup}>
          <div className={styles.content}>
            <div style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }} onClick={close}>
              X
            </div>
            <label className={`df acc ffc ffsr fs14 ${styles.selectBtn}`} htmlFor='file_input'>
              <span className={`ffnsb fs18`}>Click to upload images</span>
            </label>

            <input id='file_input' style={{ display: 'none' }} type='file' onChange={onFileChange} accept='image/*' />
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadImage;
