import { paths } from 'configs/routes';
import { Link } from 'react-router-dom';
import { LinkWrapper } from './styled';
import { Subtitle, Title } from 'shared/styled';

const NotFoundPage = () => {
  return (
    <div>
      <Title>Page not found!</Title>
      <LinkWrapper>
        <Subtitle>
          <Link to={paths.login}>Return</Link>
        </Subtitle>
      </LinkWrapper>
    </div>
  );
};

export default NotFoundPage;
