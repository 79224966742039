import { customAxios } from 'api/api';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';

import {
  ButtonWrapper,
  CustomButton,
  //   DeleteCompanyText,
  //   DeleteCompanyWrapper,
  FieldWrapper,
  FormWrapper,
  Input,
  InputWrapper,
  Label,
  RadioButtonWrapper,
  Subtitle,
  SubtitleWrapper,
  Title,
  TopFlexWrapper,
} from 'shared/styled';

import { ReactComponent as BackIcon } from 'assets/icons/back.svg';
// import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';

import styles from './New.module.scss';
import { Container, Divider, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { PROOF_METHOD } from 'configs/const/const';
import { paths } from 'configs/routes';
import UploadImage from './upload-image/UploadImage';

// async function getImageAsBase64(url) {
//   const response = await axios.get(url, {
//     responseType: 'arraybuffer',
//   });

//   const base64 = Buffer.from(response.data, 'binary').toString('base64');

//   return base64;
// }

const NewPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const sigPadRef = useRef(null);

  const { generalSlice } = useSelector(({ generalSlice }) => ({ generalSlice }));
  const [company, setCompany] = useState(undefined);
  const [signatureImageUrl, setSignatureImageUrl] = useState(undefined);
  const [logoImageUrl, setLogoImageUrl] = useState(undefined);
  const [showUploadLogo, setShowUploadLogo] = useState(false);

  const [editSignature, setEditSignature] = useState(false);
  const [blob, setBlob] = useState(null);
  const [showSaved, setShowSaved] = useState(false);
  const getCompany = async () => {
    // const base64 = await urlToBase64(
    //   'https://sellerclaimsfe.blob.core.windows.net/docs/23aLujnklHV1cbSquc.png?sp=r&st=2023-12-05T22:03:56Z&se=2055-12-06T06:03:56Z&spr=https&sv=2022-11-02&sr=b&sig=NGzxrfEGnXTcNYHi5ZJpyJ017JyjBrztcyOMlbRhzQY%3D'
    // );
    // console.log(base64);

    // const url =
    //   'https://sellerclaimsfe.blob.core.windows.net/docs/23aLujnklHV1cbSquc.png?sp=r&st=2023-12-05T22:03:56Z&se=2055-12-06T06:03:56Z&spr=https&sv=2022-11-02&sr=b&sig=NGzxrfEGnXTcNYHi5ZJpyJ017JyjBrztcyOMlbRhzQY%3D';
    // getImageAsBase64(url)
    //   .then((base64) => console.log(base64))
    //   .catch((error) => console.error(error));

    let re = await customAxios.get(`/company/${params.companyId}`, {
      headers: {
        Authorization: `Bearer ${generalSlice.token}`,
      },
    });

    setCompany(re.data);
  };

  const updateCompany = async () => {
    let re = await customAxios.patch(
      `/company/${params.companyId}`,
      { ...company },
      {
        headers: {
          Authorization: `Bearer ${generalSlice.token}`,
        },
      }
    );

    setCompany(re.data);

    setShowSaved(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowSaved(false);
    }, 3000);
  }, [showSaved]);

  useEffect(() => {
    if (!logoImageUrl)
      setCompany({
        ...company,
        logoUrl: '',
      });
  }, [logoImageUrl]);

  const uploadSignature = async () => {
    let re = await customAxios.post(
      `/company/signature`,
      { signature: sigPadRef.current.getTrimmedCanvas().toDataURL() },
      {
        headers: {
          Authorization: `Bearer ${generalSlice.token}`,
        },
      }
    );

    setCompany({ ...company, signatureFileName: re.data });
    setEditSignature(false);
  };

  const uploadLogo = async (e) => {
    // console.log('sigPadRef.current.getTrimmedCanvas().toDataURL()', e);
    let re = await customAxios.post(
      `/company/signature`,
      { signature: e },
      {
        headers: {
          Authorization: `Bearer ${generalSlice.token}`,
        },
      }
    );

    setCompany({ ...company, logoUrl: re.data });
    // setEditSignature(false);
  };

  const getSignature = async () => {
    let re = await customAxios.post(
      `/company/signature/${company?.signatureFileName}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${generalSlice.token}`,
        },
      }
    );

    setSignatureImageUrl(re?.data);
  };

  useEffect(() => {
    if (!company?.signatureFileName) return;
    getSignature();
  }, [company]);

  const getLogoUrl = async () => {
    let localLogo = await customAxios.post(
      `/company/signature/${company?.logoUrl}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${generalSlice.token}`,
        },
      }
    );

    setLogoImageUrl(localLogo?.data);
  };

  useEffect(() => {
    if (!company?.logoUrl) return;
    getLogoUrl();
  }, [company]);

  useEffect(() => {
    getCompany();
  }, [params]);
  return (
    <Container>
      <TopFlexWrapper mt={'50px'}>
        <BackIcon
          onClick={() => navigate(paths.companies)}
          style={{ cursor: 'pointer', marginRight: '18px' }}
        ></BackIcon>
        <Title>Edit Company</Title>
      </TopFlexWrapper>

      <FormWrapper>
        <SubtitleWrapper>
          <Subtitle>Edit Company</Subtitle>
        </SubtitleWrapper>
        <Divider />
        <FieldWrapper>
          <InputWrapper>
            <div>
              <Label htmlFor='companyId'>Company Name</Label>
              <Input
                id='name'
                //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                value={company?.name}
                onChange={(e) => {
                  setCompany({ ...company, name: e.target.value });
                }}
                placeholder='Enter company name'
                variant='outlined'
              />
            </div>
            <div>
              <Label htmlFor='companyId'>Company ID</Label>
              <Input
                id='companyId'
                //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                value={company?.companyId}
                onChange={(e) => {
                  setCompany({ ...company, companyId: e.target.value });
                }}
                placeholder='Enter company ID'
                variant='outlined'
              />
            </div>
          </InputWrapper>
          <RadioGroup name='proof method'>
            <RadioButtonWrapper>
              <div>
                <Label>Proof Method</Label>

                <FormControlLabel
                  value='PACKING_SLIP'
                  id='packingSlip'
                  control={<Radio />}
                  checked={company?.proofMethod === PROOF_METHOD.PACKING_SLIP}
                  onChange={(e) => {
                    setCompany({ ...company, proofMethod: PROOF_METHOD.PACKING_SLIP });
                  }}
                  label='Packing slip'
                />

                <FormControlLabel
                  value='INVOICE'
                  id='invoice'
                  control={<Radio />}
                  checked={company?.proofMethod === PROOF_METHOD.INVOICE}
                  onChange={(e) => {
                    setCompany({ ...company, proofMethod: PROOF_METHOD.INVOICE });
                  }}
                  label='Invoice'
                />
              </div>
            </RadioButtonWrapper>
          </RadioGroup>
          {company?.proofMethod === PROOF_METHOD.PACKING_SLIP ? (
            <>
              <InputWrapper>
                <div>
                  <Label htmlFor='companyAddressName'>Company Address Name</Label>
                  <Input
                    id='companyAddressName'
                    //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                    value={company?.companyAddressName}
                    onChange={(e) => {
                      setCompany({ ...company, companyAddressName: e.target.value });
                    }}
                    placeholder='Enter company name'
                    variant='outlined'
                  />
                </div>
                <div>
                  <Label htmlFor='companyAddressLine1'>Company Address Line 1</Label>
                  <Input
                    id='companyAddressLine1'
                    //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                    value={company?.companyAddressLine1}
                    onChange={(e) => {
                      setCompany({ ...company, companyAddressLine1: e.target.value });
                    }}
                    placeholder='123 Street Name'
                    variant='outlined'
                  />
                </div>
              </InputWrapper>
              <div style={{ marginTop: '20px' }}>
                <InputWrapper>
                  <div>
                    <Label htmlFor='companyId'>Company Address Last Line</Label>
                    <Input
                      id='name'
                      //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                      value={company?.companyAddressLastLine}
                      onChange={(e) => {
                        setCompany({ ...company, companyAddressLastLine: e.target.value });
                      }}
                      placeholder='City State, Zip Code'
                      variant='outlined'
                    />
                  </div>
                  <div>
                    <Label htmlFor='companyPhoneNumber'>Company Phone Number</Label>
                    <Input
                      id='companyPhoneNumber'
                      //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                      value={company?.companyPhoneNumber}
                      onChange={(e) => {
                        setCompany({ ...company, companyPhoneNumber: e.target.value });
                      }}
                      placeholder='Enter phone number'
                      variant='outlined'
                    />
                  </div>
                </InputWrapper>
                <div>
                  <p style={{ marginTop: '20px' }}>Company Logo</p>
                  {logoImageUrl || blob ? (
                    <div className={styles.logoWrpr}>
                      <img
                        height={'100px'}
                        style={{ marginTop: '12px' }}
                        src={blob || logoImageUrl}
                        onClick={() => setShowUploadLogo(true)}
                      />
                      <p
                        onClick={() => {
                          setBlob(undefined);
                          setLogoImageUrl(undefined);
                          // setCompany({ ...company, logoImageUrl: '' });
                        }}
                      >
                        Delete Logo
                      </p>
                    </div>
                  ) : (
                    <button
                      style={{
                        fontSize: '14px',
                        cursor: 'pointer',
                        background: 'rgb(27, 117, 188)',
                        color: 'white',
                        padding: '0px 18px',
                        borderRadius: '6px',
                        minHeight: '36px',
                        marginTop: '20px',
                      }}
                      onClick={() => setShowUploadLogo(true)}
                    >
                      Upload Logo
                    </button>
                  )}
                  {showUploadLogo ? (
                    <UploadImage
                      close={() => setShowUploadLogo(false)}
                      setBase64Image={(e) => uploadLogo(e)}
                      setImageBlob={(e) => setBlob(e)}
                    />
                  ) : undefined}
                </div>
                <div style={{ marginTop: '20px' }}>
                  {!signatureImageUrl || editSignature ? (
                    <>
                      <SignatureCanvas
                        penColor={'#000'}
                        canvasProps={{ width: 300, height: 80, className: styles.signature }}
                        ref={sigPadRef}
                      />

                      <div className={`df`}>
                        <button
                          style={{
                            fontSize: '14px',
                            margin: '0px 10px',
                            cursor: 'pointer',
                            color: '#c13030',
                            background: 'transparent',
                            padding: '0px 18px',
                            borderRadius: '6px',
                            minHeight: '36px',
                          }}
                          onClick={() => {
                            setEditSignature(false);
                          }}
                        >
                          Cancel
                        </button>

                        <button
                          style={{
                            fontSize: '14nter',
                            margin: '0px 10px',
                            border: '1px solid rgb(27, 117, 188)',
                            color: 'rgb(27, 117, 188)',
                            background: 'transparent',
                            padding: '0px 18px',
                            borderRadius: '6px',
                            minHeight: '36px',
                          }}
                          onClick={() => sigPadRef.current?.clear()}
                        >
                          Clear signature
                        </button>
                        <button
                          style={{
                            fontSize: '14px',
                            cursor: 'pointer',
                            background: 'rgb(27, 117, 188)',
                            color: 'white',
                            padding: '0px 18px',
                            borderRadius: '6px',
                            minHeight: '36px',
                          }}
                          onClick={() => {
                            uploadSignature();
                          }}
                        >
                          Accept signature
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        className={styles.signature}
                        style={{ height: '80px', width: '300px' }}
                        src={signatureImageUrl}
                      />
                      <div className={`df`}>
                        <button
                          onClick={() => {
                            setEditSignature(true);
                          }}
                        >
                          Edit signature
                        </button>
                      </div>
                    </>
                  )}
                </div>

                <div style={{ marginTop: '20px' }}>
                  <input
                    type='color'
                    value={company?.mainColor}
                    onChange={(e) => setCompany({ ...company, mainColor: e.target.value })}
                  />
                </div>
              </div>
            </>
          ) : undefined}

          {/* ********************************************************************************************************************** */}
          {/* ********************************************************************************************************************** */}
          {/* ********************************************************************************************************************** */}
          {/* ********************************************************************************************************************** */}
          {/* ********************************************************************************************************************** */}
          {/* ********************************************************************************************************************** */}
          {/* ********************************************************************************************************************** */}
          {/* ********************************************************************************************************************** */}
          {/* ********************************************************************************************************************** */}

          {company?.proofMethod === PROOF_METHOD.INVOICE ? (
            <>
              <InputWrapper>
                <div>
                  <Label htmlFor='companyAddressName'>Company Address Name</Label>
                  <Input
                    id='companyAddressName'
                    //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                    value={company?.companyAddressName}
                    onChange={(e) => {
                      setCompany({ ...company, companyAddressName: e.target.value });
                    }}
                    placeholder='Enter company name'
                    variant='outlined'
                  />
                </div>
                <div>
                  <Label htmlFor='companyAddressLine1'>Company Address Line 1</Label>
                  <Input
                    id='companyAddressLine1'
                    //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                    value={company?.companyAddressLine1}
                    onChange={(e) => {
                      setCompany({ ...company, companyAddressLine1: e.target.value });
                    }}
                    placeholder='123 Street Name'
                    variant='outlined'
                  />
                </div>
              </InputWrapper>

              <div style={{ marginTop: '20px' }}>
                <InputWrapper>
                  <div>
                    <Label htmlFor='companyId'>Company Address Last Line</Label>
                    <Input
                      id='name'
                      //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                      value={company?.companyAddressLastLine}
                      onChange={(e) => {
                        setCompany({ ...company, companyAddressLastLine: e.target.value });
                      }}
                      placeholder='City State, Zip Code'
                      variant='outlined'
                    />
                  </div>
                  <div>
                    <Label htmlFor='companyPhoneNumber'>Company Phone Number</Label>
                    <Input
                      id='companyPhoneNumber'
                      //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                      value={company?.companyPhoneNumber}
                      onChange={(e) => {
                        setCompany({ ...company, companyPhoneNumber: e.target.value });
                      }}
                      placeholder='Enter phone number'
                      variant='outlined'
                    />
                  </div>
                </InputWrapper>
              </div>

              <div style={{ marginTop: '20px' }}>
                <InputWrapper>
                  <div>
                    <Label htmlFor='companyEmail'>Company Email</Label>
                    <Input
                      id='companyEmail'
                      //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                      value={company?.companyEmail}
                      onChange={(e) => {
                        setCompany({ ...company, companyEmail: e.target.value });
                      }}
                      placeholder='Enter email'
                      variant='outlined'
                    />
                  </div>
                </InputWrapper>
              </div>
              <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
              <InputWrapper>
                <div>
                  <Label htmlFor='shipToAddressName'>Ship To Address Name</Label>
                  <Input
                    id='shipToAddressName'
                    //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                    value={company?.shipToAddressName}
                    onChange={(e) => {
                      setCompany({ ...company, shipToAddressName: e.target.value });
                    }}
                    placeholder='Enter ship to name'
                    variant='outlined'
                  />
                </div>

                <div>
                  <Label htmlFor='shipToAddressLine1'>Ship To Address Line 1</Label>
                  <Input
                    id='shipToAddressLine1'
                    //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                    value={company?.shipToAddressLine1}
                    onChange={(e) => {
                      setCompany({ ...company, shipToAddressLine1: e.target.value });
                    }}
                    placeholder='123 Street Name'
                    variant='outlined'
                  />
                </div>
              </InputWrapper>
              <div style={{ marginTop: '20px' }}>
                <div>
                  <Label htmlFor='shipToAddressLine2'>Ship To Address Last Line</Label>
                  <Input
                    id='shipToAddressLine2'
                    //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                    value={company?.shipToAddressLine2}
                    onChange={(e) => {
                      setCompany({ ...company, shipToAddressLine2: e.target.value });
                    }}
                    placeholder='City State, Zip Code'
                    variant='outlined'
                  />
                </div>
              </div>

              <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
              <InputWrapper>
                <div>
                  <Label htmlFor='billToAddressName'>Bill To Address Name</Label>
                  <Input
                    id='billToAddressName'
                    //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                    value={company?.billToAddressName}
                    onChange={(e) => {
                      setCompany({ ...company, billToAddressName: e.target.value });
                    }}
                    placeholder='Enter bill to name'
                    variant='outlined'
                  />
                </div>

                <div>
                  <Label htmlFor='billToAddressLine1'>Bill To Address Line 1</Label>
                  <Input
                    id='billToAddressLine1'
                    //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                    value={company?.billToAddressLine1}
                    onChange={(e) => {
                      setCompany({ ...company, billToAddressLine1: e.target.value });
                    }}
                    placeholder='123 Street Name'
                    variant='outlined'
                  />
                </div>
              </InputWrapper>
              <div style={{ marginTop: '20px' }}>
                <div>
                  <Label htmlFor='billToAddressLine2'>Bill To Address Last Line</Label>
                  <Input
                    id='billToAddressLine2'
                    //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                    value={company?.billToAddressLine2}
                    onChange={(e) => {
                      setCompany({ ...company, billToAddressLine2: e.target.value });
                    }}
                    placeholder='City State, Zip Code'
                    variant='outlined'
                  />
                </div>
                <div>
                  <Label htmlFor='billToAddressLine2'>Bill To Phone Number</Label>
                  <Input
                    id='billToAddressLine2'
                    //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                    value={company?.billToAddressLine2}
                    onChange={(e) => {
                      setCompany({ ...company, billToAddressLine2: e.target.value });
                    }}
                    placeholder='City State, Zip Code'
                    variant='outlined'
                  />
                </div>
              </div>
              <div style={{ marginTop: '20px' }}>
                <div>
                  <Label htmlFor='billToAddressLine2'>Bill To Email</Label>
                  <Input
                    id='billToAddressLine2'
                    //   $errors={errors.name || (companyNameError as FieldError | undefined)}
                    value={company?.billToAddressLine2}
                    onChange={(e) => {
                      setCompany({ ...company, billToAddressLine2: e.target.value });
                    }}
                    placeholder='City State, Zip Code'
                    variant='outlined'
                  />
                </div>
              </div>

              <div>
                <p style={{ marginTop: '20px' }}>Company Logo</p>
                {logoImageUrl || blob ? (
                  <img
                    height={'100px'}
                    style={{ marginTop: '12px' }}
                    src={blob || logoImageUrl}
                    onClick={() => setShowUploadLogo(true)}
                  />
                ) : (
                  <button
                    style={{
                      fontSize: '14px',
                      cursor: 'pointer',
                      background: 'rgb(27, 117, 188)',
                      color: 'white',
                      padding: '0px 18px',
                      borderRadius: '6px',
                      minHeight: '36px',
                      marginTop: '20px',
                    }}
                    onClick={() => setShowUploadLogo(true)}
                  >
                    Upload Logo
                  </button>
                )}
                {showUploadLogo ? (
                  <UploadImage
                    close={() => setShowUploadLogo(false)}
                    setBase64Image={(e) => uploadLogo(e)}
                    setImageBlob={(e) => setBlob(e)}
                  />
                ) : undefined}
              </div>

              <div style={{ marginTop: '20px' }}>
                <input
                  type='color'
                  value={company?.mainColor}
                  onChange={(e) => setCompany({ ...company, mainColor: e.target.value })}
                />
              </div>
            </>
          ) : undefined}

          <ButtonWrapper jc={'space-between'}>
            {/* <DeleteCompanyWrapper onClick={() => console.log(company?.id)}>
              <DeleteIcon />
              <DeleteCompanyText>Delete Company</DeleteCompanyText>
            </DeleteCompanyWrapper> */}
            <span></span>
            <CustomButton
              width={'114px'}
              height={'36px'}
              background={'#1B75BC'}
              $isBorderRadius={true}
              variant='contained'
              type='submit'
              onClick={() => {
                updateCompany();
              }}
            >
              Save
            </CustomButton>
          </ButtonWrapper>
        </FieldWrapper>
      </FormWrapper>
      {showSaved ? (
        <div className={styles.successPopup}>
          <div className={styles.content}>
            <div
              style={{ cursor: 'pointer', position: 'absolute', top: '10px', right: '10px' }}
              onClick={() => setShowSaved(false)}
            >
              X
            </div>
            <p style={{ marginTop: '20px' }}>Company saved successfully</p>
          </div>
        </div>
      ) : undefined}
    </Container>
  );
};

export default NewPage;
