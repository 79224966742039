import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { State } from './common';
import { postLogin } from './loginAction';
import {checkLocalStorageIsOld} from "../../utils/checkLocalStorageIsOld";

const initialState: State = {
  isAuthorized: checkLocalStorageIsOld() || false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setAuthorized: (state, action) => {
      state.isAuthorized = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postLogin.fulfilled, (state, { payload }) => {
        state.isAuthorized = true;
      })
      .addCase(postLogin.pending, (state) => {
        state.isAuthorized = true;
      })
      .addCase(postLogin.rejected, (state) => {
        state.isAuthorized = false;
      });
  },
});

export const {setAuthorized} = loginSlice.actions;

export default loginSlice.reducer;
