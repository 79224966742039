import styled from 'styled-components';
import { Button, TextField, InputLabel, TableCell, TableContainer, Divider, TableRow, Checkbox } from '@mui/material';
import { FieldError } from 'react-hook-form';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export const Container = styled.div`
  max-width: 1264px;
  width: 100%;
  align-self: start;
`;

export const Title = styled.h2`
  font-family: Poppins-SemiBold;
  font-size: 24px;
  color: #000310;
`;

export const Subtitle = styled.h2`
  font-family: Poppins-Medium;
  font-weight: 500;
  font-size: 20px;
  color: #000310;
`;

export const ErrorMessage = styled.div`
  color: #fa0000;
  font-family: Poppins-Regular;
  font-size: 11px;
  margin-top: 5px;
  align-self: start;
`;

export const SubtitleWrapper = styled.div`
  padding: 22px 0px 22px 48px;
`;

export const TopFlexWrapper = styled.div<{ jc?: string; mb?: string; mt?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.jc || 'unset'};
  margin-top: ${(props) => props.mt || 'unset'};
  margin-bottom: ${(props) => props.mb || '60px'};
`;

export const DragDropFlexWrapper = styled.div<{ $isShowDragDrop: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.$isShowDragDrop ? 'row' : 'column')};
  max-width: 540px;
`;

export const TopWrapper = styled.div`
  display: block;
  margin-top: 48px;
  margin-bottom: 30px;
`;

export const CustomButton = styled(Button)<{
  width?: string;
  height?: string;
  background?: string;
  $isBorderRadius?: boolean;
}>`
  &.MuiButton-root {
    background: ${(props) => props.background || '#0170b9'};
    width: ${(props) => props.width || '200px'};
    min-height: ${(props) => props.height || '40px'};
    border-radius: ${(props) => (props.$isBorderRadius ? '6px' : '0px')};
    font-family: Poppins-Medium;
    text-transform: none;
    box-shadow: none;
  }
  &.MuiButton-root.Mui-disabled {
    color: #fff;
    background-color: rgb(1 112 185 / 50%);
  }
`;

export const CustomDivider = styled(Divider)`
  &.MuiDivider-root {
    border-color: rgba(235, 237, 243, 1);
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    & .MuiSvgIcon-root {
      width: 22px;
      height: 22px;
    }

    color: #1b75bc;
  }
`;

export const TableWrapper = styled(TableContainer)<{
  component?: OverridableComponent<any>;
  mt?: string;
  $isModal?: boolean;
}>`
  max-height: ${(props) => (props.$isModal ? '400px' : 'unset')};
  &.MuiTableContainer-root {
    box-shadow: 0px 0px 25px 0px #00000017;
    border-radius: 0px;
    margin-top: ${(props) => props.mt || 'unset'};
  }
`;

export const CustomTableCell = styled(TableCell)<{ $th?: boolean }>`
  &.MuiTableCell-root {
    font-family: ${(props) => (props.$th ? 'Poppins-SemiBold' : 'Poppins-Regular')};
    font-size: 16px;
    color: #000310;
    border-bottom: 1px solid #ebedf3;
    padding: 20px;
  }
`;

export const FormWrapper = styled.div`
  box-shadow: 0px 0px 25px 0px #00000017;
  background: #ffffff;
  width: 100%;
  max-width: 821px;
  min-height: auto;
  margin: 0 auto;
`;

export const FieldWrapper = styled.div`
  padding: 32px 48px 24px 48px;
`;

export const ModalWrapper = styled.div<{ p?: string }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1055px;
  height: auto;
  max-height: 800px;
  background: #fff;
  box-shadow: 0px 0px 25px 0px #00000017;
`;

export const CustomWrapper = styled.div<{ p?: string }>`
  padding: ${(props) => props.p || 'unset'};
`;

export const InputWrapper = styled.div<{ mb?: string }>`
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  margin-bottom: ${(props) => props.mb || 'unset'};
  justify-content: space-between;
  width: 100%;
`;

export const RadioButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 260px;
  margin-top: 26px;
  margin-bottom: 26px;
`;

export const ButtonWrapper = styled.div<{ jc?: string; mt?: string; mb?: string }>`
  display: flex;
  justify-content: ${(props) => props.jc || 'unset'};
  margin-top: ${(props) => props.mt || '30px'};
  margin-bottom: ${(props) => props.mb || 'unset'};
`;

export const DragDropWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 500px;
`;

export const DragDropContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f5f9fc;
  border: 1px dashed #1b75bc;
  padding: 24px 43px;
  width: 229px;
  height: 154px;
  cursor: pointer;
`;

export const DragDropDescription = styled.div`
  font-family: Poppins-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  margin-top: 14px;
`;

export const DeleteCompanyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  cursor: pointer;
`;

export const IconWrapper = styled.div<{ jc?: string }>`
  display: flex;
  justify-content: ${(props) => props.jc || 'unset'};
  align-items: center;
  width: 62px;
`;

export const DeleteCompanyText = styled.div`
  font-family: Poppins-Regular;
  font-size: 15px;
  color: #fa0000b8;
`;

export const Input = styled(TextField)<{ $errors?: FieldError | undefined; w?: string }>`
  & .MuiOutlinedInput-root {
    border-radius: 0px;
    background: #fafafa;
    width: ${(props) => props.w || '331px'};
    height: 46px;
  }

  & .MuiOutlinedInput-input {
    font-size: 14px;
    color: ${(props) => (props.$errors ? '#FA0000' : '##000310')};
    font-family: Poppins-Regular;
    padding: 12px 14px;
  }

  & .MuiOutlinedInput-input::placeholder {
    color: #8b8f92;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => (props.$errors ? '#FA0000' : '#d9d9d9')};
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: ${(props) => (props.$errors ? '#FA0000' : '#D9D9D9')};
    color: #0170b9;
  }
`;

export const Label = styled(InputLabel)`
    &.MuiInputLabel-root {
      align-self: start;
      color: #3a3541;
      font-family: Poppins-Regular;
      font-size: 15px;
      margin-bottom: 8px;
      overflow: unset;
    }
  }
`;

export const CustomTableRow = styled(TableRow)<{ $isChecked?: boolean }>`
  &.MuiTableRow-root {
    background: ${(props) => (props.$isChecked ? '#F5F9FC' : 'unset')};
  }
`;

export const CustomHoverTableRow = styled(TableRow)<{ $isHover?: boolean }>`
  &.MuiTableRow-root:hover {
    background: ${(props) => (props.$isHover ? '#FA000014' : 'unset')};
    transition: 0.3 all;
  }
`;

export const CustomTextArea = styled(TextField)`
  & .MuiFilledInput-root {
    padding: 9px 46px 9px 12px;
    width: 277px;
    height: 66px;
    background: transparent;
    border: 1px solid #dadada;
    border-radius: 0px;
    font-family: Poppins-Regular;
  }
  & .MuiFilledInput-root:after {
    border-bottom: unset;
  }

  & .MuiFilledInput-root.Mui-focused,
  .MuiFilledInput-root:hover {
    background-color: unset;
  }

  & .MuiFilledInput-root:before {
    display: none;
  }
  & .MuiFilledInput-input {
    overflow: hidden;
  }
`;
