import React from 'react';
import { ReactComponent as LogoIcon } from 'assets/icons/sellerclaims-logo.svg';
import { Container, HeaderWrapper, PageTitle, PageTitleWrapper } from './styled';
import { Link } from 'react-router-dom';
import { paths } from 'configs/routes';
import { useAppSelector } from 'hooks/redux';
import { PAGE_TYPE } from 'configs/const/const';

const Header: React.FC = () => {
  const isAuthorized = useAppSelector((state) => state.loginSlice.isAuthorized);
  const pageType = useAppSelector((state) => state.generalSlice.pageType);

  return (
    <HeaderWrapper $isAuthorized={isAuthorized}>
      <Container $isAuthorized={isAuthorized}>
        {isAuthorized ? (
          <Link to={paths.companies}>
            <LogoIcon width={211} height={50} />
          </Link>
        ) : (
          <LogoIcon width={211} height={50} />
        )}

        {isAuthorized && (
          <PageTitleWrapper>
            <Link to={paths.companies}>
              <PageTitle $isActive={PAGE_TYPE.COMPANY === pageType}>Companies</PageTitle>
            </Link>
            <Link to={paths.shipments}>
              <PageTitle $isActive={PAGE_TYPE.SHIPMENT === pageType}>Shipments</PageTitle>
            </Link>
          </PageTitleWrapper>
        )}
      </Container>
    </HeaderWrapper>
  );
};

export default Header;
