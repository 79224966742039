import styled from 'styled-components';

export const Wrapper = styled.div<{ $isAuthorized: boolean }>`
  display: grid;
  width: 100%;
  height: 100vh;
  grid-template-areas:
    'header'
    'main';
  grid-template-columns: 1fr;
  grid-template-rows: ${(props) => (props.$isAuthorized ? '82px 1fr' : '113px 1fr')};
`;

export const MainWrapper = styled.main<{ $isAuthorized: boolean }>`
  grid-area: main;
  padding: ${(props) => (props.$isAuthorized ? '0px 88px 40px' : '0px 88px')};
  display: flex;
  align-items: center;
  justify-content: center;
`;
