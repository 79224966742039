import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { State } from './common';
import { getCompanies, getCompanyById, patchCompany, postCompany } from './companyAction';
import { FileType } from 'types/company/types';

const initialState: State = {
  csvFile: null,
  invoiceHeaderPdfFile: null,
  packingSlipHeaderPdfFile: null,
  packingSlipfooterPdfFile: null,
  proofMethod: '',
  proofMethodError: false,
  companies: {
    page: 1,
    perPage: 10,
    allPages: 2,
    total: 0,
    companies: {
      id: '',
      companyId: "",
      name: '',
      proofMethod: '',
      header: false,
      footer: false,
    },
  },
  company: {
    id: '',
    companyId: "",
    name: '',
    proofMethod: '',
    header: false,
    footer: false,
    headerUrl: [],
    footerUrl: [],
  },
  postCompanyResponse: {
    page: 1,
    perPage: 10,
    allPages: 2,
    total: 0,
    companies: {
      id: '',
      companyId: "",
      name: '',
      proofMethod: '',
      header: false,
      footer: false,
    },
  },
  isLoading: true,
  companyIdError: '',
  companyNameError: '',
  companyFileError: '',
  error: '',
};

const companySlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setClearCompany(state, action) {
      state.company = {
        id: '',
        companyId: "",
        name: '',
        proofMethod: '',
        header: false,
        footer: false,
        headerUrl: [],
        footerUrl: [],
      };
    },
    setProofMethodError(state, action: PayloadAction<boolean>) {
      state.proofMethodError = action.payload
    },
    setInvoiceHeaderPdfFile(state, action: PayloadAction<FileType | null>) {
      state.invoiceHeaderPdfFile = action.payload;
    },
    setPackingSlipHeaderPdfFile(state, action: PayloadAction<FileType | null>) {
      state.packingSlipHeaderPdfFile = action.payload;
    },
    setPackingSlipFooterPdfFile(state, action: PayloadAction<FileType | null>) {
      state.packingSlipfooterPdfFile = action.payload;
    },
    setCsvFile(state, action: PayloadAction<FileType | null>) {
      state.csvFile = action.payload;
    },
    setProofMethod(state, action: PayloadAction<string>) {
      state.proofMethod = action.payload;
    },
    setCompanyIdError(state, action: PayloadAction<string>) {
      state.companyIdError = action.payload;
    },
    setCompanyNameError(state, action: PayloadAction<string>) {
      state.companyNameError = action.payload;
    },
    setCompanyFileError(state, action: PayloadAction<string>) {
      state.companyFileError = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanies.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companies = action.payload;
      })
      .addCase(getCompanies.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message !== undefined) {
          state.error = action.error.message;
        }
      })
      .addCase(postCompany.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postCompanyResponse = action.payload;
      })
      .addCase(postCompany.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message !== undefined) {
          state.error = action.error.message;
        }
      })
      .addCase(getCompanyById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCompanyById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.company = action.payload;
      })
      .addCase(getCompanyById.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message !== undefined) {
          state.error = action.error.message;
        }
      })
  },
});

export const {
  setCsvFile,
  setClearCompany,
  setProofMethod,
  setInvoiceHeaderPdfFile,
  setPackingSlipHeaderPdfFile,
  setPackingSlipFooterPdfFile,
  setCompanyIdError,
  setCompanyNameError,
  setProofMethodError,
  setCompanyFileError
} = companySlice.actions;

export default companySlice.reducer;
