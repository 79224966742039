import { combineReducers } from '@reduxjs/toolkit';
import loginSlice from './login/loginSlice';
import companySlice from './companies/companySlice';
import shipmentSlice from './shipments/shipmentSlice';
import generalSlice from './general/generalSlice';

const rootReducer = combineReducers({
  generalSlice,
  loginSlice,
  companySlice,
  shipmentSlice,
});

export { rootReducer };
export type RootState = ReturnType<typeof rootReducer>;
