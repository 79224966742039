import { createAsyncThunk } from '@reduxjs/toolkit';
import { customAxios } from 'api/api';
import { setCompanyNameError, setCompanyIdError, setCompanyFileError } from './companySlice';
import { GeneralState } from 'store/general/common';
import { FileType, PutCompanyTypes, RequestBodyCompanyTypes, RequestCompanyTypes } from 'types/company/types';
import { PaginationParameters } from 'types/general/types';

export const postCompany = createAsyncThunk(
  'company/postCompany',
  async ({ companyId, name, proofMethod, ...rest }: RequestBodyCompanyTypes, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState() as GeneralState;
      const config = {
        headers: {
          Authorization: `Bearer ${state.generalSlice.token}`,
        },
      };
      const res = await customAxios.post('company', { companyId, name, proofMethod, ...rest }, config).catch(function (error: any) {
        if (error.response) {
          if (error.response.data.message === 'Company with this companyId already exists') {
            dispatch(setCompanyIdError(error.response.status));
          }
          if (error.response.data.message === 'Company with this name already exists') {
            dispatch(setCompanyNameError(error.response.status));
          }
        }
      });
      return res?.data;
    } catch (error) {
      return rejectWithValue(`${error}`);
    }
  }
);

export const getCompanies = createAsyncThunk(
  'company/getCompany',
  async ({ page, limit }: PaginationParameters, { rejectWithValue, getState }) => {
    try {
      const state = getState() as GeneralState;
      const config = {
        headers: {
          Authorization: `Bearer ${state.generalSlice.token}`,
        },
        params: {
          page: page,
          limit: limit,
        },
      };
      const res = await customAxios.get(`company`, config);
      return res.data;
    } catch (error) {
      return rejectWithValue(`Failed to fetch companies, ${error}`);
    }
  }
);

export const getCompanyById = createAsyncThunk(
  'company/getCompanyById',
  async (id: string, { getState, rejectWithValue }) => {
    try {
      const state = getState() as GeneralState;
      const config = {
        headers: {
          Authorization: `Bearer ${state.generalSlice.token}`,
        },
      };
      const res = await customAxios.get(`company/${id}`, config);
      return res.data;
    } catch (error) {
      return rejectWithValue(`Failed to fetch company by id, ${error}`);
    }
  }
);

export const patchCompany = createAsyncThunk(
  'company/patchCompany',
  async ({ id, companyId, name, proofMethod }: RequestCompanyTypes, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState() as GeneralState;
      const config = {
        headers: {
          Authorization: `Bearer ${state.generalSlice.token}`,
        },
      };

      const res = await customAxios.patch(`company/${id}`, { companyId, name, proofMethod }, config).catch(function (error: any) {
        if (error.response) {
          if (error.response.data.message === 'Company with this companyId already exists') {
            dispatch(setCompanyIdError(error.response.status));
          }
          if (error.response.data.message === 'Company with this name already exists') {
            dispatch(setCompanyNameError(error.response.status));
          }
        }
      });

      return res?.data;
    } catch (error: any) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      } else if (error.request) {
        return rejectWithValue('Failed to send patch request to server.');
      } else {
        return rejectWithValue(`An error occurred: ${error.message}`);
      }
    }
  }
);

export const deleteCompany = createAsyncThunk(
  'company/deleteCompany',
  async (id: string, { getState, rejectWithValue }) => {
    try {
      const state = getState() as GeneralState;
      const config = {
        headers: {
          Authorization: `Bearer ${state.generalSlice.token}`,
        },
      };
      const res = await customAxios.delete(`company/${id}`, config);

      return res.data;
    } catch (error) {
      return rejectWithValue(`Failed to delete company, ${error}`);
    }
  }
);

export const putCompany = createAsyncThunk(
  'company/putCompany',
  async ({ id, name, file }: PutCompanyTypes, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState() as GeneralState;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${state.generalSlice.token}`,
        },
      };
      const res = await customAxios.put(`company/${id}/template/${name}`, { file }, config).catch(function (error: any) {
        if (error.response) {
          if (error.response.data.message === 'File not found') {
            dispatch(setCompanyFileError(error.response.status));
          }
        }
      });

      return res?.data;
    } catch (error) {
      return rejectWithValue(`Failed to update company, ${error}`);
    }
  }
);

export const postCsv = createAsyncThunk('company/postCsv', async (file: FileType, { getState, rejectWithValue }) => {
  try {
    const state = getState() as GeneralState;
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${state.generalSlice.token}`,
      },
    };
    const res = await customAxios.post('company/csv', { file }, config);

    return res.data;
  } catch (error) {
    return rejectWithValue(`Failed to send csv, ${error}`);
  }
});
