export const paths = {
  login: '/login',
  companies: '/companies',
  edit: '/companies/edit/',
  editById: '/companies/edit/:companyId?',
  manually: '/companies/create/manually',
  upload: '/companies/create/upload',
  shipments: '/shipments',
  shipment: '/shipments/selected-shipment/',
  shipmentById: '/shipments/selected-shipment/:fbaId',
};
