import styled from 'styled-components';

export const HeaderWrapper = styled.header<{ $isAuthorized?: boolean }>`
  min-width: 100%;
  height: ${(props) => (props.$isAuthorized ? '82px' : '113px')};
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  box-shadow: 0px 0px 25px 0px #00000017;
  grid-area: header;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 88px;
`;

export const LogoWrapper = styled.div<{ $isAuthorized?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.$isAuthorized ? 'start' : 'center')};
`;

export const Container = styled.div<{ $isAuthorized?: boolean }>`
  max-width: 1264px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.$isAuthorized ? 'space-between' : 'center')};
`;

export const PageTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 250px;
`;

export const PageTitle = styled.h3<{ $isActive?: boolean }>`
  color: ${(props) => (props.$isActive ? '#1B75BC' : '#000310')};
  font-size: 18px;
  font-family: Poppins-Medium;
  cursor: pointer;
  font-weight: 500;
  &::after {
    content: '';
    display: ${(props) => (props.$isActive ? 'block' : '#none')};
    margin: 4px auto 0;
    height: 6px;
    width: 6px;
    background-color: #1b75bc;
  }
`;
