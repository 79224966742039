import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ActionType, State } from './common';
import {checkLocalStorageIsOld} from "../../utils/checkLocalStorageIsOld";

const initialState: State = {
  pageType: '',
  token: checkLocalStorageIsOld() || '',
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setPageType(state, action: PayloadAction<string>) {
      state.pageType = action.payload;
    },
    setToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload;
    },
  },
});

export const { setPageType, setToken } = generalSlice.actions;

export default generalSlice.reducer;
