export const PROOF_METHOD = {
  PACKING_SLIP: 'PACKING_SLIP',
  INVOICE: 'INVOICE',
};

export const ROW_TYPES = {
  SHIPMENT_ITEMS: 'SHIPMENT_ITEMS',
  SHIPMENTS: 'SHIPMENTS',
  COMPANIES: 'COMPANIES',
  MODAL: 'MODAL',
};

export const DESCRIPTION = {
  CSV: 'Drag & drop CSV file',
  HEADER_PDF: 'Drag & drop files for header template',
  FOOTER_PDF: 'Drag & drop files for footer template',
};

export const PDF_TYPES = {
  INVOICE_HEADER_PDF_TYPE: 'INVOICE_HEADER_PDF_TYPE',
  PACKING_SLIP_HEADER_PDF_TYPE: 'PACKING_SLIP_HEADER_PDF_TYPE',
  PACKING_SLIP_FOOTER_PDF_TYPE: 'PACKING_SLIP_FOOTER_PDF_TYPE',
};

export const FILE_TYPE = { PDF: ['PDF'], CSV: ['CSV'] };

export const FILE_NAME = {
  HEADER: 'header.pdf',
  FOOTER: 'footer.pdf',
};

export const PDF_NAME = {
  HEADER_PDF_NAME: 'header',
  FOOTER_PDF_NAME: 'footer',
};

export const PAGE_TYPE = {
  SHIPMENT: 'SHIPMENT',
  COMPANY: 'COMPANY',
};

export const ERROR_STATUS = {
  CONFLICT: '409',
};

export const DRAG_DROP = [
  {
    type: PDF_TYPES.PACKING_SLIP_HEADER_PDF_TYPE,
    description: DESCRIPTION.HEADER_PDF,
  },
  {
    type: PDF_TYPES.PACKING_SLIP_FOOTER_PDF_TYPE,
    description: DESCRIPTION.FOOTER_PDF,
  },
];

export const COMPANIES_TABLE_TITLE = [
  {
    title: 'Company ID',
  },
  {
    title: 'Company Name',
  },
  {
    title: 'Proof Method',
  },
  // {
  //   title: 'Header Template',
  // },
  // {
  //   title: 'Footer Template',
  // },
  {
    title: 'Actions',
  },
];

export const SHIPMENTS_TABLE_TITLE = [
  {
    title: 'Company ID',
  },
  {
    title: 'Shipment Date',
  },
  {
    title: 'FBA ID',
  },
  {
    title: 'Discrepancy QTY',
  },
  {
    title: 'Actions',
  },
];

export const MODAL_TABLE_TITLE = [
  {
    title: 'Quantity',
  },
  {
    title: 'SKU',
  },
  {
    title: 'Description',
  },
  {
    title: 'Shipped Amount',
  },
  {
    title: 'Received Amount',
  },
];
