import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { State } from './common';
import {
  getShipmentById,
  getShipments,
  postShipmentInvoice,
} from './shipmentAction';
import {
  SelectedShipmentItemsTypes,
  ShipmentDescriptionTypes,
  ShipmentItemsTypes,
  ShipmentSkuTypes,
} from 'types/shipment/types';

const initialState: State = {
  isCheckedAll: false,
  isLoading: true,
  shipment: {
    id: 'no id',
    companyId: 0,
    shipmentDate: 'no shipment date',
    shipFrom: {
      city: 'string',
      name: 'string',
      postalCode: 'string',
      countryCode: 'string',
      addressLine1: 'string',
      stateOrProvinceCode: 'string',
    },
    shipTo: {
      city: 'string',
      postalCode: 'string',
      countryCode: 'string',
      addressLine1: 'string',
      stateOrProvinceCode: 'string',
    },
    fbaId: 'no fba id',
    discrepancyQty: 0,
    items: {
      quantity: 0,
      sku: 'string',
      receivedAmount: 0,
      description: 'string',
      price: 0,
    },
    proofMethod: 'PACKING_SLIP',
  },
  shipments: {
    page: 1,
    perPage: 10,
    allPages: 1,
    total: 0,
    shipments: [
      {
        id: '0',
        companyId: 0,
        shipmentDate: 'no shipment date',
        fbaId: 'no fba id',
        discrepancyQty: 0,
      },
    ],
  },
  error: '',
  shipmentsArray: [],
  checkedShipments: [],
  invoiceIdError: '',
  invoiceDateError: '',
  invoiceError: '',
  descriptions: [],
  descriptionError: '',
  skus: []
};

const shipmentSlice = createSlice({
  name: 'shipments',
  initialState,
  reducers: {
    setIsChecked(state, action: PayloadAction<SelectedShipmentItemsTypes>) {
      state.shipmentsArray = state.shipmentsArray?.map((data: ShipmentItemsTypes) => {
        if (data.sku === action.payload.sku) return { ...data, checked: true };
        return data;
      });
      state.checkedShipments.push(action.payload);
      state.descriptions.push(action.payload);
      state.skus.push(action.payload);
    },
    setIsNotChecked(state, action: PayloadAction<string>) {
      state.shipmentsArray = state.shipmentsArray?.map((data: ShipmentItemsTypes) => {
        if (data.sku === action.payload) return { ...data, checked: false };
        return data;
      });
      state.checkedShipments = state.checkedShipments.filter(
        (data: SelectedShipmentItemsTypes) => data.sku !== action.payload
      );
      state.descriptions = state.descriptions.filter(
        (data: SelectedShipmentItemsTypes) => data.sku !== action.payload
      );
      state.skus = state.skus.filter(
        (data: SelectedShipmentItemsTypes) => data.sku !== action.payload
      );
      state.isCheckedAll = false;
    },
    setCheckedShipmentsAll(state) {
      state.shipmentsArray = state.shipmentsArray?.map((data: ShipmentItemsTypes) => {
        return { ...data, checked: true };
      });
      state.checkedShipments = state.shipmentsArray;
      state.descriptions = state.shipmentsArray;
      state.skus = state.shipmentsArray;
      state.isCheckedAll = true;
    },
    setDeleteCheckedShipmentsAll(state) {
      state.shipmentsArray = state.shipmentsArray?.map((data: ShipmentItemsTypes) => {
        return { ...data, checked: false };
      });
      state.checkedShipments = [];
      state.descriptions = []
      state.skus = []
      state.isCheckedAll = false;
    },
    setInvoiceNumberError(state, action: PayloadAction<string>) {
      state.invoiceIdError = action.payload;
    },
    setInvoiceDateError(state, action: PayloadAction<string>) {
      state.invoiceDateError = action.payload;
    },
    setDescription(state, action: PayloadAction<ShipmentDescriptionTypes>) {
      console.log("LLIONUGNIL", action.payload)
      if (action.payload.type === "sku" && action.payload.sku) {
        state.descriptions = state.descriptions
          .map((item) =>
            item.sku === action.payload.idSKU
              ? { ...item, updatedSku: action.payload.sku }
              : item)
      } else if (action.payload.type === "description" && action.payload.description) {
        state.descriptions = state.descriptions
          .map((item) =>
            item.sku === action.payload.idSKU
              ? { ...item, description: action.payload.description || "" }
              : item)
      }

      // state.descriptions = state.descriptions
      // .map((item) =>
      //   item.sku === action.payload.idSKU
      //     ? { ...item, description: action.payload.description }
      //     : item)

    },

    setSku(state, action: PayloadAction<ShipmentSkuTypes>) {
      console.log(action)
      state.skus = state.skus
        .map((item) =>
          item.sku === action.payload.idSKU
            ? { ...item, updatedSku: action.payload.sku }
            : item)
    },
    setDescriptionError(state, action: PayloadAction<string>) {
      state.descriptionError = action.payload
    },
    clearDescription(state) {
      state.descriptions = []
      state.skus = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShipments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getShipments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.shipments = action.payload;
      })
      .addCase(getShipments.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message !== undefined) {
          state.error = action.error.message;
        }
      })
      .addCase(getShipmentById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getShipmentById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCheckedAll = false;
        state.checkedShipments = [];
        state.shipment = action.payload;
        state.shipmentsArray = action.payload.items?.map((data: ShipmentItemsTypes) => ({ ...data, checked: false }));
      })
      .addCase(getShipmentById.rejected, (state, action) => {
        state.isLoading = false;
        if (action.error.message !== undefined) {
          state.error = action.error.message;
        }
      })
      .addCase(postShipmentInvoice.rejected, (state, action) => {
        state.invoiceIdError = action.payload as string
      })
  },
});

export const {
  setIsChecked,
  setIsNotChecked,
  setCheckedShipmentsAll,
  setDeleteCheckedShipmentsAll,
  setInvoiceNumberError,
  setDescription,
  setSku,
  clearDescription,
  setDescriptionError
} = shipmentSlice.actions;

export default shipmentSlice.reducer;
