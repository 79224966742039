import { baseUrl } from '../configs/axios';
import { errorCatch } from './helpers';
import axios, { AxiosError } from 'axios';

export const customAxios = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

customAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError<Error>) => {
    console.error(errorCatch(error));
    return Promise.reject(error);
  }
);
